import { paths } from "../../../../backend/common/generated/types/api";
import { ShopPaymentType } from "../../../../backend/common/payment.types";
import { PDFType } from "../../../../backend/common/pdf.types";
import {
  AcceptOrRejectNewSupplementInput,
  AddNoteInput,
  CreateClaimInput,
  Document,
  PaidShopInput,
  RateServiceInput,
  ScheduleDropoffInput,
  SchedulePickupInput,
  UpdateClaimInput,
} from "../../../../backend/common/request.types";

import {
  DELETE,
  GET,
  getAuthenticatedRequestHeaders,
  makeRequest,
  PATCH,
  POST,
  PUT,
} from "./serviceUtils";

export async function updateShopECD(requestId: number, ecd: Date | null) {
  return await POST("/request/{requestId}/shopECD", {
    params: { path: { requestId } },
    body: { ecd: ecd ? ecd.toISOString() : null },
    headers: await getAuthenticatedRequestHeaders(),
  });
}

export async function updateNextFollowUp(requestId: number, ecd: Date | null) {
  return await PATCH("/request/{requestId}/nextFollowUp", {
    params: { path: { requestId } },
    body: { nextFollowUpAt: ecd ? ecd.toISOString() : null },
    headers: await getAuthenticatedRequestHeaders(),
  });
}

export async function updateCustomerECD(requestId: number, ecd: Date | null) {
  return await POST("/request/{requestId}/customerECD", {
    params: { path: { requestId } },
    body: { ecd: ecd ? ecd.toISOString() : null },
    headers: await getAuthenticatedRequestHeaders(),
  });
}

export async function acceptRequest(requestId: number) {
  return await POST("/request/{requestId}/acceptShopAssignment", {
    params: { path: { requestId } },
    headers: await getAuthenticatedRequestHeaders(),
  });
}

export async function rejectRequest(
  requestId: number,
  rejectionReason: string
) {
  return await POST("/request/{requestId}/rejectShopAssignment", {
    params: { path: { requestId } },
    body: { rejectionReason },
    headers: await getAuthenticatedRequestHeaders(),
  });
}

export async function closeRequest(requestId: number) {
  return await POST("/request/{requestId}/close", {
    params: { path: { requestId } },
    headers: await getAuthenticatedRequestHeaders(),
  });
}

export async function approveInvoice(requestId: number) {
  return await POST("/request/{requestId}/approveInvoice", {
    params: { path: { requestId } },
    headers: await getAuthenticatedRequestHeaders(),
  });
}

export async function setScheduledDropoff(requestId: number) {
  return await POST("/request/{requestId}/setScheduledDropoff", {
    params: { path: { requestId } },
    headers: await getAuthenticatedRequestHeaders(),
  });
}

export async function schedulePickup(
  requestId: number,
  data: SchedulePickupInput
) {
  return await POST("/request/{requestId}/schedulePickup", {
    params: { path: { requestId } },
    body: {
      ...data,
      startPickupDate: data.startPickupDate.toISOString(),
      endPickupDate: data.endPickupDate.toISOString(),
    },
    headers: await getAuthenticatedRequestHeaders(),
  });
}

export async function schedulePickupWithoutAuth(
  requestId: number,
  userId: number,
  data: SchedulePickupInput
) {
  return await POST("/request/{requestId}/schedulePickup/{userId}", {
    params: { path: { requestId, userId } },
    body: {
      ...data,
      startPickupDate: data.startPickupDate.toISOString(),
      endPickupDate: data.endPickupDate.toISOString(),
    },
  });
}

export async function setScheduledPickup(requestId: number) {
  return await POST("/request/{requestId}/setScheduledPickup", {
    params: { path: { requestId } },
    headers: await getAuthenticatedRequestHeaders(),
  });
}

export async function scheduleDropoffWithoutAuth(
  requestId: number,
  userId: number,
  data: ScheduleDropoffInput
) {
  return await POST("/request/{requestId}/scheduleDropoff/{userId}", {
    params: { path: { requestId, userId } },
    body: {
      ...data,
      startDropoffDate: data.startDropoffDate.toISOString(),
      endDropoffDate: data.endDropoffDate.toISOString(),
    },
  });
}

export async function scheduleDropoff(
  requestId: number,
  data: ScheduleDropoffInput
) {
  return await POST("/request/{requestId}/scheduleDropoff", {
    params: { path: { requestId } },
    body: {
      ...data,
      startDropoffDate: data.startDropoffDate.toISOString(),
      endDropoffDate: data.endDropoffDate.toISOString(),
    },
    headers: await getAuthenticatedRequestHeaders(),
  });
}

export async function insertNotes(requestId: number, data: AddNoteInput) {
  return await POST("/request/{requestId}/note", {
    params: { path: { requestId } },
    body: { ...data },
    headers: await getAuthenticatedRequestHeaders(),
  });
}

type UploadFilesInput =
  paths["/channel/{channelId}/uploadFiles"]["post"]["requestBody"]["content"]["application/json"];
export async function uploadFiles(channelId: number, data: UploadFilesInput) {
  return await POST("/channel/{channelId}/uploadFiles", {
    params: { path: { channelId } },
    body: { ...data },
    headers: await getAuthenticatedRequestHeaders(),
  });
}

export async function workCompleted(
  requestId: number,
  data: {
    mileageOut: number;
    images: { imageUrl: string }[];
    note?: string | null;
    channelId: number;
  }
) {
  const requestOptions = {
    method: "POST",
    headers: await getAuthenticatedRequestHeaders(),
    body: JSON.stringify({ ...data }),
  };

  const url = `${process.env.NEXT_PUBLIC_APIV2_URL}/request/${requestId}/workCompleted`;
  return makeRequest(url, requestOptions);
}

export async function addShopInvoice(
  requestId: number,
  data: {
    shopInvoice: Document;
    partsReceipts?: Document[];
    laborTotal?: number | null;
    partsTotal?: number | null;
    otherTotal?: number | null;
    note?: string | null;
    shopPaymentType?: ShopPaymentType;
    taxTotal?: number | null;
  }
) {
  return await POST("/request/{requestId}/addShopInvoice", {
    params: { path: { requestId } },
    body: { ...data },
    headers: await getAuthenticatedRequestHeaders(),
  });
}

export async function inShop(
  requestId: number,
  data: {
    shopECD: string | null;
    images: { imageUrl: string }[];
    note: string | null;
    channelId: number;
  }
) {
  return await POST("/request/{requestId}/inShop", {
    params: { path: { requestId } },
    body: { ...data },
    headers: await getAuthenticatedRequestHeaders(),
  });
}

export async function diagnostics(
  requestId: number,
  data: {
    additionalWorkRecommended: boolean;
    mileageIn: number;
    shopECD: string;
    images: { imageUrl: string }[];
    note: string | null;
    channelId: number;
  }
) {
  return await POST("/request/{requestId}/diagnostics", {
    params: { path: { requestId } },
    body: { ...data },
    headers: await getAuthenticatedRequestHeaders(),
  });
}

export async function newSupplement(
  requestId: number,
  data: {
    shopECD: string;
    documents: Document[];
    images: { imageUrl: string }[];
    note: string | null;
    channelId: number;
  }
) {
  return await POST("/request/{requestId}/newSupplement", {
    params: { path: { requestId } },
    body: { ...data },
    headers: await getAuthenticatedRequestHeaders(),
  });
}

export async function acceptOrRejectNewSupplement(
  requestId: number,
  data: AcceptOrRejectNewSupplementInput
) {
  return await POST("/request/{requestId}/newSupplement/acceptOrReject", {
    params: { path: { requestId } },
    body: { ...data },
    headers: await getAuthenticatedRequestHeaders(),
  });
}

export async function paidShop(requestId: number, data: PaidShopInput) {
  return await POST("/request/{requestId}/paidShop", {
    params: { path: { requestId } },
    body: { ...data },
    headers: await getAuthenticatedRequestHeaders(),
  });
}

export async function rateService(requestId: number, data: RateServiceInput) {
  return await POST("/request/{requestId}/rateService", {
    params: { path: { requestId } },
    body: { ...data },
    headers: await getAuthenticatedRequestHeaders(),
  });
}

export async function requestPayment(requestId: number) {
  return await POST("/request/{requestId}/requestPayment", {
    params: { path: { requestId } },
    headers: await getAuthenticatedRequestHeaders(),
  });
}

export async function cancelRequest(
  requestId: number,
  data: {
    reason: string;
    comments?: string;
  }
) {
  return await POST("/request/{requestId}/closeLost", {
    params: { path: { requestId } },
    body: { ...data },
    headers: await getAuthenticatedRequestHeaders(),
  });
}

export async function deleteUploadedFiles(
  requestId: number,
  requestHistoryId: number
) {
  return await DELETE("/request/{requestId}/history/{requestHistoryId}", {
    params: { path: { requestId, requestHistoryId } },
    headers: await getAuthenticatedRequestHeaders(),
  });
}

const CLOSED_STATII = new Set(["closed", "lost", "declined"]);
const ASK_FOR_INVOICE_STATII = new Set([
  "awaitingPayment",
  "paid",
  "scheduledDropoff",
  "deliveryInProgress",
  "closed",
]);

export const isRequestOpen = (status: string): boolean => {
  return !CLOSED_STATII.has(status);
};

export const isRequestInvoice = (status: string): boolean => {
  return ASK_FOR_INVOICE_STATII.has(status);
};

export async function getRequestPDF(requestId: number, pdfType: PDFType) {
  try {
    const requestOptions = {
      method: "GET",
      headers: await getAuthenticatedRequestHeaders(),
    };
    const response = await fetch(
      `${process.env.NEXT_PUBLIC_APIV2_URL}/request/${requestId}/pdf/${pdfType}`,
      requestOptions
    );

    if (!response.ok) {
      const error = await response.json();
      console.error("Unable to generate PDF %s", error);
      return { error: error?.message ?? "Unable to generate PDF" };
    }

    return response;
  } catch (error) {
    console.error("Unable to generate PDF %j", error);
    return { error };
  }
}

export async function removeDiscountCode(requestId: number, data: string) {
  return await POST("/request/{requestId}/removePromoCode", {
    params: { path: { requestId } },
    body: { discountCode: data },
    headers: await getAuthenticatedRequestHeaders(),
  });
}

export async function canApproveEstimate(requestId: number) {
  return await GET("/request/{requestId}/approvalPermission", {
    params: { path: { requestId } },
    headers: await getAuthenticatedRequestHeaders(),
  });
}

export async function readMessages(
  requestId: number,
  channelId: number,
  readDate?: Date
) {
  return await POST("/request/{requestId}/lastReadMessage", {
    params: { path: { requestId } },
    headers: await getAuthenticatedRequestHeaders(),
    body: {
      channelId,
      readDate: readDate?.toISOString() ?? undefined,
    },
  });
}

export async function getUnreadRequests(requestIds: number[]) {
  const requestIdStringArray = requestIds.map(String);
  return await GET("/request/unreadRequests", {
    params: { query: { requestIds: requestIdStringArray } },
    headers: await getAuthenticatedRequestHeaders(),
  });
}

export async function applyPromoCode(requestId: number, discountCode: string) {
  return await POST("/request/{requestId}/applyPromoCode", {
    params: { path: { requestId } },
    body: { discountCode },
    headers: await getAuthenticatedRequestHeaders(),
  });
}

export async function updateZipCode(requestId: number, zipCode: string) {
  return await POST("/request/{requestId}/updateZipCode", {
    params: { path: { requestId } },
    body: { zipCode },
    headers: await getAuthenticatedRequestHeaders(),
  });
}

export async function createClaim(requestId: number, claim: CreateClaimInput) {
  return await POST("/request/{requestId}/createClaim", {
    params: { path: { requestId } },
    body: { ...claim },
    headers: await getAuthenticatedRequestHeaders(),
  });
}

export async function updateClaim(
  requestId: number,
  id: number,
  claim: UpdateClaimInput
) {
  return await PUT("/request/{requestId}/claim/{id}", {
    params: { path: { requestId, id } },
    body: { ...claim },
    headers: await getAuthenticatedRequestHeaders(),
  });
}

export async function markBellNotificationsAsRead(requestId: number) {
  return await POST("/request/{requestId}/bellNotifications/read", {
    params: { path: { requestId } },
    headers: await getAuthenticatedRequestHeaders(),
  });
}

type AddMessagePath =
  paths["/channel/{channelId}/message"]["post"]["parameters"]["path"];
type AddMessageInput =
  paths["/channel/{channelId}/message"]["post"]["requestBody"]["content"]["application/json"];
export async function insertMessages(
  path: AddMessagePath,
  body: AddMessageInput
) {
  return await POST("/channel/{channelId}/message", {
    params: { path },
    body,
    headers: await getAuthenticatedRequestHeaders(),
  });
}

export async function deleteMessage(messageId: number) {
  return await DELETE("/channel/message/{messageId}", {
    params: { path: { messageId } },
    headers: await getAuthenticatedRequestHeaders(),
  });
}
