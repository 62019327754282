import { ReactNode, useEffect, useState } from "react";

import { initialize } from "launchdarkly-js-client-sdk";
import { asyncWithLDProvider } from "launchdarkly-react-client-sdk";

import { useAuth } from "./AuthProvider";

interface FeatureFlagProviderProps {
  children: ReactNode;
}

// The LaunchDarkly default logger is very verbose. We really only care about bad stuff, so this
// logger is more discerning about what it logs.
const WarningLogger = {
  warn: console.warn,
  error: console.error,
  info: (_message: string) => void 0,
  debug: (_message: string) => void 0,
};

export function FeatureFlagProvider({ children }: FeatureFlagProviderProps) {
  const { currentUser } = useAuth();
  const [LDProvider, setLDProvider] = useState<Awaited<
    ReturnType<typeof asyncWithLDProvider>
  > | null>(null);

  useEffect(() => {
    let ldClient: ReturnType<typeof initialize> | undefined;

    async function initializeLD() {
      let context: Parameters<typeof initialize>[1];
      if (currentUser) {
        context = {
          kind: "user",
          key: currentUser.uid,
          email: currentUser.email ?? undefined,
          name: currentUser.displayName ?? undefined,
        };
      } else {
        context = {
          kind: "user",
          anonymous: true,
        };
      }

      ldClient = initialize(
        process.env.NEXT_PUBLIC_LAUNCHDARKLY_CLIENT_KEY,
        context,
        { streaming: false, logger: WarningLogger }
      );
      const LDProviderComponent = await asyncWithLDProvider({
        clientSideID: process.env.NEXT_PUBLIC_LAUNCHDARKLY_CLIENT_KEY,
        // The LaunchDarkly docs recommend a timeout of 5s or less
        timeout: 5,
        ldClient,
        context,
        options: {
          streaming: false,
        },
      });

      setLDProvider(() => LDProviderComponent);
    }

    initializeLD();

    return () => {
      ldClient?.close();
    };
  }, [currentUser]);

  if (!LDProvider) return;

  return <LDProvider>{children}</LDProvider>;
}
